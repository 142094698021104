import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';
import { UserModel, IUserCreateForm, IUserEditForm, OtpMethod } from '../models/user.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { ICreateRes } from '@monorepo/tools/src/lib/interfaces/crud';
import { PublisherModel } from '../models/publisher.model';

export interface ICredentials {
	email: string;
	password: string;
	rememberMe?: boolean;
}
export interface IVerify {
	token: string;
	userId: string | number;
	method: string;
}

export const UsersApi = {
	list: (params?: URLSearchParams): Promise<UserModel[]> => {
		return ask.get(endpoints.users.list(), params).then(res => res.payload.list);
	},
	reports: (params?: URLSearchParams): Promise<UserModel[]> => {
		return ask.get('endpoints.campaigns.reports()', params);
	},
	// metrics: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
	// 	const hashParams = btoa(JSON.stringify(params));
	// 	return ask.get(endpoints.campaigns.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	// },
	create: (user: IUserCreateForm, options?: IRequestOptions): Promise<UserModel> => {
		return ask.post<IUserCreateForm>(endpoints.users.create(), user, options);
	},
	signUp: (user: IUserCreateForm, options?: IRequestOptions): Promise<{ body: IUserCreateForm; headers: string[][] }> => {
		return ask.postWithHeaders<IUserCreateForm>(endpoints.users.signUp(), user, options);
	},
	signIn: (signIn: ICredentials, options?: IRequestOptions): Promise<{ body: IUserCreateForm; headers: string[][] }> => {
		return ask.postWithHeaders<IUserCreateForm>(endpoints.users.signIn(), signIn, options);
	},
	validate2fa: (verify: IVerify, options?: IRequestOptions): Promise<{ body: IVerify; headers: string[][] }> => {
		return ask.postWithHeaders<IVerify>(endpoints.users.validate2fa(), verify, options);
	},
	resend2fa: (
		userId: string,
		method: OtpMethod,
		options?: IRequestOptions
	): Promise<{ body: { userId: string; method: OtpMethod }; headers: string[][] }> => {
		return ask.postWithHeaders<{ userId: string; method: OtpMethod }>(endpoints.users.resend2fa(), { userId, method }, options);
	},
	edit: (userId: id, user: IUserEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.put<IUserEditForm>(endpoints.users.edit(userId), user, options);
	},
	get: (userId: id, options?: URLSearchParams): Promise<UserModel> => {
		return ask.get(endpoints.users.get(userId), options).then(res => res.payload.item);
	},
	profile: (options?: URLSearchParams): Promise<UserModel> => {
		return ask.get(endpoints.users.profile(), options).then(res => res.payload.item);
	},
	editProfile: (options?: { oldPassword: string; newPassword: string }): Promise<UserModel> => {
		return ask.put(endpoints.users.profile(), options).then(res => res.payload.item);
	},
	resetPassword: (params: { token: string; password: string }, options?: IRequestOptions): Promise<null> => {
		return ask.post(endpoints.users.resetPassword(), params, options);
	},
	forgotPassword: (email: string, options?: IRequestOptions): Promise<null> => {
		return ask.post(endpoints.users.forgotPassword(), { email }, options);
	},
	delete: (userId: id) => {
		return ask.delete(endpoints.users.delete(userId));
	},
	verify: (verifyToken: string, options?: IRequestOptions): Promise<FullResponse<ICreateRes<UserModel>>> => {
		return ask.postWithHeaders(endpoints.users.verify(verifyToken), {}, options);
	},
	sendVerifyEmail: (params: undefined, options?: IRequestOptions): Promise<FullResponse<void>> => {
		return ask.postWithHeaders<void>(endpoints.users.sendVerifyEmail(), params, options);
	},
	getRoles: (params?: URLSearchParams): Promise<string[]> => {
		return ask.get(endpoints.users.getRoles(), params).then(res => res.payload.item);
	},
	getAccountList: (params?: URLSearchParams): Promise<PublisherModel[]> => {
		return ask.get(endpoints.users.getAccountList(), params).then(res => res.payload);
	},
	// login: (email: string, password: string) => {
	// 	return ask.post(endpoints.users.baseUrl + '/login', { email, password });
	// },
};

import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { Page } from '@monorepo/base/src/components/page/page';
import styles from '../../../styles/pages.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { InputDesc, InputInfo, InputItem, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { Input } from '@monorepo/base/src/components/form/input/input';
import Timer from '@monorepo/base/src/components/timer/timer';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import actAsStyles from './act-as-verification.module.scss';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IUser } from '../../../modules/models/user.model';
import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import routes from '../../../modules/routes/index.routes';

const bouncerUrl = `${getConfig().env.sheriffRootApi}/bouncer`;

const useActAsVerifyMailRoute = async (userId: string): Promise<any> => {
	return await ask.getWithHeaders<{ payload: { actAs: IUser } }>(`${bouncerUrl}/users/act-as/verify/${userId}`);
};

const resendActAsVerifyMailRoute = async (userId: string): Promise<any> => {
	return await ask.getWithHeaders<{ payload: { actAs: IUser } }>(`${bouncerUrl}/users/act-as/resend-verify/${userId}`);
};

const generateTempStore = (userObject: { token: string; user: IUser }, targetId: string) => {
	ActAsHelper.setToken(userObject.token, targetId);

	return (window.location.href = `${routes.general.home()}?PUV=${targetId}`);
};

export const ActAsVerify: FC = observer(() => {
	const userId = useParams<{ id: string }>().id;
	const didMount = useDidMount();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isTimer, setIsTimer] = useState<boolean>(false);
	const [otp, setOtp] = useState<string>('');
	const [isVerifiedOnce, setIsVerifiedOnce] = useState<boolean>(false);
	const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
	const [timerKey, setTimerKey] = useState<number>(0);
	const [error, setError] = useState<string | undefined>();

	useEffect(() => {
		if (userId && didMount) {
			ActAsHelper.deleteUser(userId);
			useActAsVerifyMailRoute(userId).finally(() => {
				setIsLoading(false);
				setIsTimer(true);
			});
		}
	}, [didMount]);

	const useActAsVerificationRoute = async (userId: id, otp: string): Promise<any> => {
		setIsLoading(true);
		let response: any = {};
		try {
			response = await ask.postWithHeaders<{ otp?: string; payload?: { actAs: IUser; actAsToken: string } }>(
				`${bouncerUrl}/users/act-as/verification/${userId}`,
				{
					otp,
				}
			);
		} catch (error: any) {
			setError(error.data.message);
		} finally {
			setIsLoading(false);
		}

		return {
			token: response.body?.payload?.actAsToken,
		};
	};

	const onVerify = async ({ userId, otp }: { userId: id; otp: string }) => {
		setIsVerifiedOnce(true);

		if (!userId) {
			setError('User id is missing');
		}

		const userObject = await useActAsVerificationRoute(userId, otp);
		if (userObject.token) {
			generateTempStore(userObject, userId as string);
		}
	};

	const onResend = (userId: string) => {
		setIsLoading(true);
		setIsTimer(false);
		resendActAsVerifyMailRoute(userId).finally(() => {
			setIsLoading(false);
			setIsTimer(true);
		});
	};

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Manifesto - Act as Verification</title>
			</Helmet>
			{/* <Pagebar classes={{ pagebar: styles.padding }} debugProps={{ dataAttrs: [new DataAttribute('id', 'pagebar')] }}>
				<PagebarTitle>Act as Verification</PagebarTitle>
			</Pagebar> */}
			<div className={actAsStyles.formWrapper}>
				<div className={actAsStyles.otpWrapper}>
					<InputItem flexDirection={'column'} gap={'16px'}>
						<InputInfo className={styles.inputInfo}>
							<InputTitle>OTP</InputTitle>
							<InputDesc>Please check your mail and enter the code</InputDesc>
						</InputInfo>
						<Input
							placeholder={'Enter your code'}
							onValue={value => {
								if (Number(value) >= 0 || value === '') {
									setOtp(value);
								}
								if (otp.length === 6 && !isVerifiedOnce) {
									onVerify({ userId: userId + '', otp });
								}
							}}
							type={'text'}
							className={styles.inputWrapper}
							value={otp}
							requiredAsterisk={false}
							maxLength={6}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'act_as_verify')] }}
						/>
					</InputItem>
					<PrimaryButton
						disabled={isLoading}
						isRotateIcon={isLoading}
						iconSize={'20px'}
						icon={isLoading ? 'loading-01' : ''}
						className={styles.verifyBtn}
						onClick={() => onVerify({ userId: userId + '', otp })}>
						Verify
					</PrimaryButton>
					{error ? <span className={actAsStyles.error}>{error}</span> : null}
					{isTimer && (
						<span className={actAsStyles.resend2Fa}>
							Didn't receive your 2FA code?&nbsp;
							<span
								className={`${actAsStyles.cta} ${!userId || isResendDisabled ? actAsStyles.disabled : ''}`}
								onClick={() => {
									if (userId) {
										setIsResendDisabled(true);
										if (userId && !isResendDisabled) {
											setTimerKey(prevKey => prevKey + 1);
											onResend(userId);
										}
									}
								}}>
								Click here to resend code.
							</span>
							<div className={actAsStyles.timerWrapper}>
								<Timer
									classNames={{ timerWrapper: actAsStyles.compTimerWrapper }}
									isDisappeaerOnTimeup={true}
									key={timerKey}
									initialTime={60}
									handleTimeup={() => setIsResendDisabled(false)}
									beforeText={'('}
									afterText={')'}
								/>
							</div>
						</span>
					)}
				</div>
			</div>
		</Page>
	);
});

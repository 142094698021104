import { UserModel, IUserCreateForm, IUserEditForm, OtpMethod } from '../../models/user.model';
import { ICredentials, UsersApi } from '../../apis/users.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { runInAction } from 'mobx';
import { ErrorPayloadType, HttpError, IHttpError } from '../../models/http-error.model';
import { getStringBetweenParentheses, validateEmail } from '@monorepo/tools/src/lib/utils/string';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import parsePhoneNumber from 'libphonenumber-js';

export interface IUserRes {
	payload: { user: UserModel };
}

export class UserCrudStore extends BaseCrudStore<UserModel, IUserCreateForm, IUserEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: UsersApi,
			model: UserModel,
			errorModel: HttpError,
		});
	}

	private extractIdFromEntityId = (entityId: string): string => {
		if (entityId === 'All') {
			return '*';
		}

		return getStringBetweenParentheses(entityId);
	};

	/**
	 * Must call isValidUser before calling this function
	 * @returns
	 */
	public getCreateFormData(): IUserCreateForm {
		const domainRoles = this.getData()
			.getDomainRolesArray()
			.map(domainRole => {
				const id: id = this.extractIdFromEntityId(domainRole.domain as string);

				return {
					domain: `mfp/account/${id}`,
					roles: domainRole.roles,
				};
			});

		return {
			firstName: this.getData().getFirstName(),
			lastName: this.getData().getLastName(),
			company: this.getData().getCompany(),
			email: this.getData().getEmail(),
			required2fa: this.getData().getRequired2Fa() || true,
			domainRoles,
			phone: this.getData().getPhone(),
		};
	}

	/**
	 * Must call isValidUser before calling this function
	 * @returns
	 */
	public getEditFormData(): IUserEditForm {
		const domainRoles = this.getData()
			.getDomainRolesArray()
			.map(domainRole => ({
				domain: `mfp/account/${this.extractIdFromEntityId(domainRole.domain as string)}`,
				roles: domainRole.roles,
			}));

		return {
			id: this.getData().getId(),
			firstName: this.getData().getFirstName(),
			lastName: this.getData().getLastName(),
			company: this.getData().getCompany(),
			required2fa: this.getData().getRequired2Fa() || true,
			domainRoles,
			phone: this.getData().getPhone(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		this.clearHttpError();
		const firstName = this.getData().getFirstName();
		const lastName = this.getData().getLastName();
		const email = this.getData().getEmail();
		const company = this.getData().getCompany();
		const domainRoles = this.getData().getDomainRolesArray() || [];
		const phone = this.getData().getPhone();

		if (!firstName?.length) {
			this.formStore.addError(new FormError('first_name', 'Please enter first name'));
		}

		if (!lastName?.length) {
			this.formStore.addError(new FormError('last_name', 'Please enter last name'));
		}

		if (!company?.length) {
			this.formStore.addError(new FormError('company', 'Please enter company'));
		}

		if (!email?.length || !validateEmail(email)) {
			this.formStore.addError(new FormError('email', 'Please enter valid email'));
		}

		if (phone) {
			const parsedPhone = parsePhoneNumber(phone);
			if (phone.charAt(0) !== '+') {
				this.formStore.addError(new FormError('phone', 'Missing country code prefix'));
			} else if (!parsedPhone || !parsedPhone.isValid()) {
				this.formStore.addError(new FormError('phone', 'Invalid phone number'));
			}
		}

		domainRoles.map(domainRole => {
			const tempRole = { ...(domainRole || {}) };
			if (tempRole.roles?.length === 0 && tempRole.errorMessages) {
				tempRole.errorMessages.rolesErrorMessage = 'Please select role';
			}

			if (!tempRole.domain && tempRole.errorMessages) {
				tempRole.errorMessages.entityErrorMessage = 'Please select entity';
			}

			return tempRole;
		});

		if (!domainRoles.every(domainRole => domainRole.domain && (domainRole.roles || []).length > 0)) {
			this.formStore.addError(new FormError('roles', 'Please select an account'));
		}

		return this.formStore.getIsValid();
	}

	public signInValidation(): boolean {
		this.formStore.reset();
		this.clearHttpError();
		const email = this.getData().getEmail();
		const password = this.getData().getPassword();
		if (!email) {
			this.formStore.addError(new FormError('email', 'Please enter email'));
		}

		if (email && !validateEmail(email)) {
			this.formStore.addError(new FormError('email', 'Please enter valid email'));
		}

		if (!password) {
			this.formStore.addError(new FormError('password', 'Please enter password'));
		}

		return this.formStore.getIsValid();
	}

	public verify2faValidation(): boolean {
		this.formStore.reset();
		this.clearHttpError();
		const code = this.getData().getCode();
		if (!code) {
			this.formStore.addError(new FormError('code', 'Please enter a valid code'));
		}

		return this.formStore.getIsValid();
	}

	public signIn(): Promise<FullResponse<IUserRes> | void> {
		const isValid = this.signInValidation();
		if (!isValid) {
			this.setHttpError(new HttpError({ message: 'Please fix the issues above and try again' } as IHttpError));
			return Promise.resolve();
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);

		const credentials: ICredentials = {
			// after validation
			email: this.getData().getEmail() as string,
			password: this.getData().getPassword() as string,
			rememberMe: this.getData().getRememberMe() as boolean,
		};

		return UsersApi.signIn(credentials)
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(error => {
				error.data.httpStatus = error.response.status;
				this.onError(error.data);
				runInAction(() => {
					this.setHttpError(new HttpError(error.data));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public validate2fa({
		userId,
		token,
		method,
	}: {
		userId: string;
		token: string;
		method: string;
	}): Promise<FullResponse<IUserRes> | void> {
		const isValid = this.verify2faValidation();
		if (!isValid) {
			this.setHttpError(new HttpError({ message: 'Please fix the issues above' } as IHttpError));
			return Promise.resolve();
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);

		return UsersApi.validate2fa({ userId, token, method })
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(error => {
				this.onError(error.data);
				runInAction(() => {
					this.setHttpError(new HttpError(error.data));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public resend2fa(userId: string, method: OtpMethod) {
		return UsersApi.resend2fa(userId, method)
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(error => {
				this.onError(error.data);
				runInAction(() => {
					this.setHttpError(new HttpError(error.data));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public signUp(): Promise<FullResponse<IUserRes> | void> {
		const isValid = this.isValid();
		if (!isValid) {
			this.setHttpError(new HttpError({ message: 'Please fix the issues above' } as IHttpError));
			return Promise.resolve();
		}
		this.setIsLoading(true);
		this.setIsSuccess(false);

		return UsersApi.signUp(this.getCreateFormData())
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(error => {
				this.onError(error.data);
				runInAction(() => {
					this.setHttpError(new HttpError(error.data));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public verify(verifyToken: string): Promise<FullResponse<IUserRes> | void> {
		if (!verifyToken) {
			return Promise.resolve();
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);

		return UsersApi.verify(verifyToken)
			.then(res => {
				const { body } = res;
				runInAction(() => {
					if (body?.payload?.item) {
						this.setData(body.payload.item);
					}
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(err => {
				runInAction(() => {
					this.setHttpError(new HttpError({ message: err?.payload?.message } as IHttpError));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return err;
			});
	}

	public profile(): Promise<UserModel> {
		this.setIsLoading(true);
		this.setIsSuccess(false);
		this.setHttpError(null);

		return UsersApi.profile()
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(error => {
				this.onError(error.data);
				runInAction(() => {
					this.setHttpError(new HttpError(error.data));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public onError(error: IHttpError) {
		if (error?.errorPayload?.type === ErrorPayloadType.Properties) {
			Object.keys(error.errorPayload?.data).forEach(key => {
				this.formStore.addError(new FormError(key, error.errorPayload?.data[key] as string));
			});
		}
	}
}

import styles from './signin.module.scss';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { observer } from 'mobx-react-lite';
// import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { FC, useEffect, useState } from 'react';
import routes from '../../../modules/routes/index.routes';
import { ThirdPartyAuth } from '../../../modules/components/third-party-auth/third-party-auth';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPPStore } from '../../../modules/stores';
import { sessionStorageHelper } from '@monorepo/tools/src/lib/tools/storage';
import { URL_TO_RETURN_AFTER_LOGOUT } from '../../../lib/consts/storage';
import { IUser, OtpMethod, UserModel } from '../../../modules/models/user.model';
import { HttpHeaders } from '@monorepo/tools/src/lib/enums/headers';
import { ErrorMsg, ErrorWithTimeLeft } from '../../../modules/components/error-msg/error-msg';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export const SignIn: FC = observer(() => {
	const { userStore, authStore } = useStores<IMFPPStore>();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const crudStore = userStore.getCrud();
	const user = crudStore.getData();
	const formStore = crudStore.getFormStore();
	const error = crudStore.getHttpError();
	const isLoading = crudStore.getIsLoading();
	const [, setSearchParams] = useSearchParams();

	useEffect(() => {
		setSearchParams();
		crudStore.reset();
	}, []);

	const onSignIn = async () => {
		const signInProps = await crudStore.signIn();
		if (!signInProps) {
			return;
		}
		const { body, headers } = signInProps as FullResponse<{
			payload: { user: IUser; use2FA?: boolean; qrCode?: string; method?: OtpMethod; allowedMethods?: OtpMethod[] };
		}>;
		if (!body?.payload?.user) {
			return;
		}

		if (body?.payload?.use2FA) {
			const userModel = new UserModel(body?.payload?.user);
			const qrCode = body?.payload?.qrCode;
			const qrEncoded = qrCode ? encodeURIComponent(qrCode) : null;
			const method = body?.payload?.method || 'email';
			window.location.href = `${routes.auth.twoFactor()}?${qrCode ? `qrCode=${qrEncoded}&` : ''}userId=${
				userModel.id
			}&method=${method}&allowedMethods=${body?.payload?.allowedMethods?.join(',')}`;
			return;
		}

		const userAuthToken = headers?.find(h => h[0] === HttpHeaders.USER_TOKEN)?.[1];

		if (!userAuthToken) {
			return;
		}

		const user = new UserModel(body.payload.user);
		userStore.setUser(user);
		authStore.setToken(userAuthToken);

		if (!user.isVerified()) {
			window.location.href = routes.auth.verify();
			return;
		}

		const urlToReturnTo = sessionStorageHelper.getItem(URL_TO_RETURN_AFTER_LOGOUT);
		window.location.href = urlToReturnTo || routes.general.home();
	};

	const renderError = () => {
		if (!error) {
			return null;
		}

		if (error.httpStatus === 429) {
			return <ErrorWithTimeLeft beforeTimerText={'try again in '} error={error} isIcon={false} classes={{ error: styles.error }} />;
		}

		return <ErrorMsg error={error} isIcon={false} classes={{ error: styles.error }} />;
	};

	return (
		<div className={styles.signinContent}>
			<Helmet>
				<title>Manifesto - Login</title>
			</Helmet>
			<div className={styles.title}>Login</div>
			<div className={styles.subtitle}>Welcome back! Please enter your details.</div>
			<ThirdPartyAuth />
			<div className={styles.lineSeperator}>
				<span className={styles.lineText}>or Sign in with Email</span>
			</div>

			<div className={styles.formWrapper}>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Email</div>
					<Input
						className={styles.formInput}
						placeholder={'Enter your email'}
						onValue={v => user.setEmail(v.trim())}
						value={user.getEmail()}
						error={formStore.getErrors().get('email')?.getMsg()}
						id={'email'}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Password</div>
					<Input
						className={styles.formInput}
						value={user.getPassword()}
						placeholder={'Enter your Password'}
						onValue={v => user.setPassword(v)}
						onKeyDown={e => e.key === 'Enter' && user.getPassword()?.length && user.getEmail()?.length && onSignIn()}
						requiredAsterisk={false}
						type={!showPassword ? 'password' : 'text'}
						after={
							<Icon onClick={() => setShowPassword(!showPassword)} className={styles.passwordVisibilityIcon} isMFP={true}>
								{!showPassword ? 'eye' : 'eye-off'}
							</Icon>
						}
						error={formStore.getErrors().get('password')?.getMsg()}
						id={'password'}
					/>
				</div>
				<div className={styles.formOptions}>
					{/* <div className={styles.rememberMe}>
						<Checkbox onChange={() => user.setRememberMe(!user.getRememberMe())} />
						<div>Keep me signed in</div>
					</div> */}
					<LinkWithParams
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.LOGIN.LOGIN,
							});
						}}
						to={routes.auth.forgetPassword()}
						className={styles.forgotPassword}>
						Forgot Password
					</LinkWithParams>
				</div>
				<PrimaryButton
					isRotateIcon={isLoading}
					iconSize={'20px'}
					icon={isLoading ? 'loading-01' : ''}
					className={styles.signInBtn}
					onClick={() => {
						sendGtagEvent({
							action: TrackingActions.Click,
							category: EVENTS.CLICK.LOGIN.LOGIN,
						});
						onSignIn();
					}}>
					Sign in
				</PrimaryButton>
				{/* {error ? <ErrorMsg error={error} isIcon={false} classes={{ error: styles.error }} /> : null} */}
				{renderError()}
				<div className={styles.termsAndPrivacy}>
					<span>By signing in I agree to the </span>
					<Link
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.LOGIN.TERMS_AND_CONDITIONS,
							});
						}}
						className={styles.terms}
						to={routes.general.termsAndConditions()}
						target={'_blank'}>
						Terms
					</Link>
					<span> and </span>
					<Link
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.LOGIN.PRIVACY_POLICY,
							});
						}}
						className={styles.policy}
						to={routes.general.privacyPolicy()}
						target={'_blank'}>
						Privacy Policy
					</Link>
				</div>
			</div>
		</div>
	);
});
